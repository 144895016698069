@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,900'); //sass-lint:disable-line no-url-domains no-url-protocols

// Font family
$font-family-native: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
$font-family-primary: 'Roboto', $font-family-native;

// Font size
$font-size-base-mobile: .9rem;
$font-size-base: .95rem;
$font-size-article: $font-size-base;
$font-size-tiny: .75rem;
$font-size-small: .85rem;
$font-size-lead: 1.4rem;
$font-size-medium: 1.6rem;
$font-size-headings: 2rem;
$font-size-headings-large: 2.5rem;

// Font weight
$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-bold: 600;
$font-weight-black: 900;

//  ==================================================
//  colors

$color-white: #fff;
$color-text: #333;
$color-link: #111;
$color-link-pages: #0070c9;
$color-label: #777;
$color-lead: $color-label;
$color-navbar: #444;
$color-muted: #888;

$color-warning: #fde74c;

$active: #2d9cdb;
$inactive: #999;

$color-primary: #2d9cdb;

$color-subtitles: #828282;

$ui-borders: #bbb;
$ui-borders-dark: #444;
$ui-borders-medium: #999;

$color-fluc: #00467f;
$color-fduc: #eb5757;
$color-fmuc: #f2c94c;
$color-fctuc: #0096d7;
$color-feuc-a: #eb5757;
$color-feuc-b: #ddd;
$color-ffuc: #53247f;
$color-fpce: #f99d31;
$color-ca: #9fa1a4;
$color-iii: #38939e;
$color-icnas: #00856f;

//  ==================================================
//  grid

$gap: 30px;
$radius: 5px;

//  ==================================================
//  media helpers

$mobile-max: 639px;
$tablet: 640px;
$desktop: 960px;
$desktop-hd: 1200px;
$desktop-fhd: 1400px;
