@import '../vars';

.ucpa-web.app-silva,
.uc-site-layout.app-silva {

  // Google GSE
  .gsc-overflow-hidden {
    overflow: initial; }

  .beta-label {
    float: right;
    line-height: 90%;
    font-weight: 300;
    margin: 20px 15px 0;
    font-size: 1rem;

    @media (min-width: $desktop) {
      margin: 28px 15px 0;
      font-size: $font-size-small; } }

  .agendauc-component {
    background: #eee;

    .card-events {
      background: #fff;

      .dates {
        background-color: rgba(255, 153, 0, .8); } }

    h2 {
      color: $color-text; } }

  .error-page {
    //filter: grayscale(100%)

    &.error-forbidden,
    &.error-internal {
      h1 {
        color: #f0506e; } }

    .uk-card {
      background: rgba(255, 255, 255, .9);
      padding: $gap;

      @media (min-width: $desktop) {
        padding: $gap * 2; }

      form {
        &.uk-search {
          width: 100%; } }

      h1 {
        font-weight: 400;

        small {
          font-size: 60%; } }

      .logo {
        svg {
          width: 200px; } } } }

  .silva-document {

    p {
      &.p {
        margin-top: 15px;
        margin-bottom: 15px;

        & + p {
          &.p {
            margin-top: 0; } }

        & + h2,
        & + h3,
        & + h4,
        & + h5,
        & + h6 {
          margin-top: 20px; } } }

    p:empty,
    h2:empty,
    h3:empty,
    h4:empty,
    h5:empty,
    h6:empty {
      display: none; }

    a {
      color: $color-link-pages; }

    h1,
    h2 {
      font-size: 1.3rem;

      @media (min-width: $tablet) {
        font-size: 1.7rem; } }

    h3 {
      font-size: 1.2rem;

      @media (min-width: $tablet) {
        font-size: 1.3rem; } }

    h4 {
      font-size: 1.1rem;
      font-weight: 400; }

    h5 {
      font-size: 1rem; }

    h6 {
      font-size: $font-size-base;

      &.minor {
        font-size: $font-size-small;
        color: #888; } }

    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 130%;
      margin-bottom: 15px;

      &:first-child {
        margin-top: 0; }

      & + p {
        &.p {
          margin-top: 0; } } }

    sup > sub,
    sub > sup {
      font-size: inherit; }

    table {
      &.silvatable {
        width: 100%;
        margin: 9px 0;

        td {
          vertical-align: top;
          text-align: left;

          h2,
          h3,
          h4,
          h5 {
            &.heading:first-child {
              margin-top: 0; } }

          &.align-center {
            text-align: center; }

          &.align-left {
            text-align: left; }

          &.align-right {
            text-align: right; } }

        &.list {
          td {
            padding: 10px;
            border-top: 1px dotted #ccc; } }

        &.grid {
          td {
            padding: 10px;
            border: 1px dotted #ccc; } }

        &.datagrid {
          td {
            padding: 10px;
            border: 1px solid #999; } } } }

    img {
      &.float-left {
        float: left;
        margin: 0 9px 9px 0; }

      &.float-right {
        float: right;
        margin: 0 0 9px 9px; } }

    ul {
      padding-left: 20px;

      @media (min-width: $desktop) {
        padding-left: $gap; }

      li {
        margin-bottom: $gap / 3;
        list-style-type: disc; }

      &.disc {
        li {
          list-style-type: disc; } } } } }
