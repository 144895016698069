@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,900");
.ucpa-web.app-silva .gsc-overflow-hidden,
.uc-site-layout.app-silva .gsc-overflow-hidden {
  overflow: initial; }

.ucpa-web.app-silva .beta-label,
.uc-site-layout.app-silva .beta-label {
  float: right;
  line-height: 90%;
  font-weight: 300;
  margin: 20px 15px 0;
  font-size: 1rem; }
  @media (min-width: 960px) {
    .ucpa-web.app-silva .beta-label,
    .uc-site-layout.app-silva .beta-label {
      margin: 28px 15px 0;
      font-size: 0.85rem; } }

.ucpa-web.app-silva .agendauc-component,
.uc-site-layout.app-silva .agendauc-component {
  background: #eee; }
  .ucpa-web.app-silva .agendauc-component .card-events,
  .uc-site-layout.app-silva .agendauc-component .card-events {
    background: #fff; }
    .ucpa-web.app-silva .agendauc-component .card-events .dates,
    .uc-site-layout.app-silva .agendauc-component .card-events .dates {
      background-color: rgba(255, 153, 0, 0.8); }
  .ucpa-web.app-silva .agendauc-component h2,
  .uc-site-layout.app-silva .agendauc-component h2 {
    color: #333; }

.ucpa-web.app-silva .error-page.error-forbidden h1, .ucpa-web.app-silva .error-page.error-internal h1,
.uc-site-layout.app-silva .error-page.error-forbidden h1,
.uc-site-layout.app-silva .error-page.error-internal h1 {
  color: #f0506e; }

.ucpa-web.app-silva .error-page .uk-card,
.uc-site-layout.app-silva .error-page .uk-card {
  background: rgba(255, 255, 255, 0.9);
  padding: 30px; }
  @media (min-width: 960px) {
    .ucpa-web.app-silva .error-page .uk-card,
    .uc-site-layout.app-silva .error-page .uk-card {
      padding: 60px; } }
  .ucpa-web.app-silva .error-page .uk-card form.uk-search,
  .uc-site-layout.app-silva .error-page .uk-card form.uk-search {
    width: 100%; }
  .ucpa-web.app-silva .error-page .uk-card h1,
  .uc-site-layout.app-silva .error-page .uk-card h1 {
    font-weight: 400; }
    .ucpa-web.app-silva .error-page .uk-card h1 small,
    .uc-site-layout.app-silva .error-page .uk-card h1 small {
      font-size: 60%; }
  .ucpa-web.app-silva .error-page .uk-card .logo svg,
  .uc-site-layout.app-silva .error-page .uk-card .logo svg {
    width: 200px; }

.ucpa-web.app-silva .silva-document p.p,
.uc-site-layout.app-silva .silva-document p.p {
  margin-top: 15px;
  margin-bottom: 15px; }
  .ucpa-web.app-silva .silva-document p.p + p.p,
  .uc-site-layout.app-silva .silva-document p.p + p.p {
    margin-top: 0; }
  .ucpa-web.app-silva .silva-document p.p + h2,
  .ucpa-web.app-silva .silva-document p.p + h3,
  .ucpa-web.app-silva .silva-document p.p + h4,
  .ucpa-web.app-silva .silva-document p.p + h5,
  .ucpa-web.app-silva .silva-document p.p + h6,
  .uc-site-layout.app-silva .silva-document p.p + h2,
  .uc-site-layout.app-silva .silva-document p.p + h3,
  .uc-site-layout.app-silva .silva-document p.p + h4,
  .uc-site-layout.app-silva .silva-document p.p + h5,
  .uc-site-layout.app-silva .silva-document p.p + h6 {
    margin-top: 20px; }

.ucpa-web.app-silva .silva-document p:empty,
.ucpa-web.app-silva .silva-document h2:empty,
.ucpa-web.app-silva .silva-document h3:empty,
.ucpa-web.app-silva .silva-document h4:empty,
.ucpa-web.app-silva .silva-document h5:empty,
.ucpa-web.app-silva .silva-document h6:empty,
.uc-site-layout.app-silva .silva-document p:empty,
.uc-site-layout.app-silva .silva-document h2:empty,
.uc-site-layout.app-silva .silva-document h3:empty,
.uc-site-layout.app-silva .silva-document h4:empty,
.uc-site-layout.app-silva .silva-document h5:empty,
.uc-site-layout.app-silva .silva-document h6:empty {
  display: none; }

.ucpa-web.app-silva .silva-document a,
.uc-site-layout.app-silva .silva-document a {
  color: #0070c9; }

.ucpa-web.app-silva .silva-document h1,
.ucpa-web.app-silva .silva-document h2,
.uc-site-layout.app-silva .silva-document h1,
.uc-site-layout.app-silva .silva-document h2 {
  font-size: 1.3rem; }
  @media (min-width: 640px) {
    .ucpa-web.app-silva .silva-document h1,
    .ucpa-web.app-silva .silva-document h2,
    .uc-site-layout.app-silva .silva-document h1,
    .uc-site-layout.app-silva .silva-document h2 {
      font-size: 1.7rem; } }

.ucpa-web.app-silva .silva-document h3,
.uc-site-layout.app-silva .silva-document h3 {
  font-size: 1.2rem; }
  @media (min-width: 640px) {
    .ucpa-web.app-silva .silva-document h3,
    .uc-site-layout.app-silva .silva-document h3 {
      font-size: 1.3rem; } }

.ucpa-web.app-silva .silva-document h4,
.uc-site-layout.app-silva .silva-document h4 {
  font-size: 1.1rem;
  font-weight: 400; }

.ucpa-web.app-silva .silva-document h5,
.uc-site-layout.app-silva .silva-document h5 {
  font-size: 1rem; }

.ucpa-web.app-silva .silva-document h6,
.uc-site-layout.app-silva .silva-document h6 {
  font-size: 0.95rem; }
  .ucpa-web.app-silva .silva-document h6.minor,
  .uc-site-layout.app-silva .silva-document h6.minor {
    font-size: 0.85rem;
    color: #888; }

.ucpa-web.app-silva .silva-document h2,
.ucpa-web.app-silva .silva-document h3,
.ucpa-web.app-silva .silva-document h4,
.ucpa-web.app-silva .silva-document h5,
.ucpa-web.app-silva .silva-document h6,
.uc-site-layout.app-silva .silva-document h2,
.uc-site-layout.app-silva .silva-document h3,
.uc-site-layout.app-silva .silva-document h4,
.uc-site-layout.app-silva .silva-document h5,
.uc-site-layout.app-silva .silva-document h6 {
  line-height: 130%;
  margin-bottom: 15px; }
  .ucpa-web.app-silva .silva-document h2:first-child,
  .ucpa-web.app-silva .silva-document h3:first-child,
  .ucpa-web.app-silva .silva-document h4:first-child,
  .ucpa-web.app-silva .silva-document h5:first-child,
  .ucpa-web.app-silva .silva-document h6:first-child,
  .uc-site-layout.app-silva .silva-document h2:first-child,
  .uc-site-layout.app-silva .silva-document h3:first-child,
  .uc-site-layout.app-silva .silva-document h4:first-child,
  .uc-site-layout.app-silva .silva-document h5:first-child,
  .uc-site-layout.app-silva .silva-document h6:first-child {
    margin-top: 0; }
  .ucpa-web.app-silva .silva-document h2 + p.p,
  .ucpa-web.app-silva .silva-document h3 + p.p,
  .ucpa-web.app-silva .silva-document h4 + p.p,
  .ucpa-web.app-silva .silva-document h5 + p.p,
  .ucpa-web.app-silva .silva-document h6 + p.p,
  .uc-site-layout.app-silva .silva-document h2 + p.p,
  .uc-site-layout.app-silva .silva-document h3 + p.p,
  .uc-site-layout.app-silva .silva-document h4 + p.p,
  .uc-site-layout.app-silva .silva-document h5 + p.p,
  .uc-site-layout.app-silva .silva-document h6 + p.p {
    margin-top: 0; }

.ucpa-web.app-silva .silva-document sup > sub,
.ucpa-web.app-silva .silva-document sub > sup,
.uc-site-layout.app-silva .silva-document sup > sub,
.uc-site-layout.app-silva .silva-document sub > sup {
  font-size: inherit; }

.ucpa-web.app-silva .silva-document table.silvatable,
.uc-site-layout.app-silva .silva-document table.silvatable {
  width: 100%;
  margin: 9px 0; }
  .ucpa-web.app-silva .silva-document table.silvatable td,
  .uc-site-layout.app-silva .silva-document table.silvatable td {
    vertical-align: top;
    text-align: left; }
    .ucpa-web.app-silva .silva-document table.silvatable td h2.heading:first-child,
    .ucpa-web.app-silva .silva-document table.silvatable td h3.heading:first-child,
    .ucpa-web.app-silva .silva-document table.silvatable td h4.heading:first-child,
    .ucpa-web.app-silva .silva-document table.silvatable td h5.heading:first-child,
    .uc-site-layout.app-silva .silva-document table.silvatable td h2.heading:first-child,
    .uc-site-layout.app-silva .silva-document table.silvatable td h3.heading:first-child,
    .uc-site-layout.app-silva .silva-document table.silvatable td h4.heading:first-child,
    .uc-site-layout.app-silva .silva-document table.silvatable td h5.heading:first-child {
      margin-top: 0; }
    .ucpa-web.app-silva .silva-document table.silvatable td.align-center,
    .uc-site-layout.app-silva .silva-document table.silvatable td.align-center {
      text-align: center; }
    .ucpa-web.app-silva .silva-document table.silvatable td.align-left,
    .uc-site-layout.app-silva .silva-document table.silvatable td.align-left {
      text-align: left; }
    .ucpa-web.app-silva .silva-document table.silvatable td.align-right,
    .uc-site-layout.app-silva .silva-document table.silvatable td.align-right {
      text-align: right; }
  .ucpa-web.app-silva .silva-document table.silvatable.list td,
  .uc-site-layout.app-silva .silva-document table.silvatable.list td {
    padding: 10px;
    border-top: 1px dotted #ccc; }
  .ucpa-web.app-silva .silva-document table.silvatable.grid td,
  .uc-site-layout.app-silva .silva-document table.silvatable.grid td {
    padding: 10px;
    border: 1px dotted #ccc; }
  .ucpa-web.app-silva .silva-document table.silvatable.datagrid td,
  .uc-site-layout.app-silva .silva-document table.silvatable.datagrid td {
    padding: 10px;
    border: 1px solid #999; }

.ucpa-web.app-silva .silva-document img.float-left,
.uc-site-layout.app-silva .silva-document img.float-left {
  float: left;
  margin: 0 9px 9px 0; }

.ucpa-web.app-silva .silva-document img.float-right,
.uc-site-layout.app-silva .silva-document img.float-right {
  float: right;
  margin: 0 0 9px 9px; }

.ucpa-web.app-silva .silva-document ul,
.uc-site-layout.app-silva .silva-document ul {
  padding-left: 20px; }
  @media (min-width: 960px) {
    .ucpa-web.app-silva .silva-document ul,
    .uc-site-layout.app-silva .silva-document ul {
      padding-left: 30px; } }
  .ucpa-web.app-silva .silva-document ul li,
  .uc-site-layout.app-silva .silva-document ul li {
    margin-bottom: 10px;
    list-style-type: disc; }
  .ucpa-web.app-silva .silva-document ul.disc li,
  .uc-site-layout.app-silva .silva-document ul.disc li {
    list-style-type: disc; }
